import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { iconWarning, iconHandOrange, iconQuizLP01 } from "../../lib/images";
import { HashLink } from "react-router-hash-link";
import "./Calendar.style.scss";
import { Trans, useTranslation } from "react-i18next";

function Calendar(props) {
  let isHome = props.isHome;
  const { t } = useTranslation();
  return (
    <div>
      <Container id="calendar" className="calendar-section" fluid>
        <Row noGutters>
          <div className={isHome ? "title peach-color" : "title"}>
            {t("save_these_important")}
          </div>
          <div className="leader">
            {/* And be sure to{" "}
							<HashLink to='/#voter-status' smooth>
								<strong>check your voter registration status</strong>
							</HashLink>{" "}
							to confirm you’re all set to cast your vote this election */}
            <p>
              <Trans i18nKey="check_your_voter">
                <HashLink to="/#voter-status"></HashLink>
              </Trans>
            </p>
          </div>
        </Row>
        <Row className="calendar-grid">
          <Col xs="12" xl="7" className="col-left">
            <Container className="ctn-text-content-note" fluid>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="primary_early_voting_starts">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="may_27_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="primary_early_voting_ends">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="june_13_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="primary_election">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="june_17_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="runoff_election">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="july_15_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="early_voting_starts">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="oct_14_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="last_day_to_request">
                    <span class="language-customization-es"></span>
                    <br />
                  </Trans>
                  <Trans i18nKey="a_vote_by_mail_ballot">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="oct_24_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="early_voting_ends">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="oct_31_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="election_day">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="nov_4_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col xs="6" sm="6" xl="7" className="text-content-note-step">
                  <Trans i18nKey="runoff_election">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col xs="1" xl="1" className="col-hand-orange">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                </Col>
                <Col xs="4" sm="5" xl="4" className="text-content-note-t">
                  <p>
                    <Trans i18nKey="dec_2_2025">
                      <span class="language-customization-es"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </Trans>
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs="12" xl="5" className="col-right d-none d-xl-block">
            <Image src={iconQuizLP01} />
          </Col>{" "}
        </Row>
      </Container>
    </div>
  );
}
export default Calendar;
