import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Calendar, LineSplit } from "../../components";
import "./RegisterToVote.style.scss";
import { Trans, useTranslation } from "react-i18next";

function RegisterToVote() {
  const { t } = useTranslation();
  return (
    <div className="ctn-register-tovote">
      <Calendar />
    </div>
  );
}
export default RegisterToVote;
