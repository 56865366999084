import React, { useState, useRef, useEffect } from "react";
import { Container, Image, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./AnswerQuestions.style.scss";
import {
  altColorV2,
  checkRegister,
  infoRegister,
  iconStartOver,
  iconCheck,
  iconChecked,
} from "../../lib/images";
import axios from "axios";
import moment from "moment";
import config from "../../lib/config";
import * as qs from "qs";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";
import IMask from "imask";

function AnswerQuestions() {
  const [quizStep, setStep] = useState(0);
  const [CD, setCD] = useState(0);
  const [SD, setSD] = useState(0);
  const [HD, setHD] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("INITIAL");
  const [formFilled, setFormFilled] = useState(false);
  const [keepme, setKeepme] = useState(true);
  const [sourceParam, setSourceParam] = useState(
    sessionStorage.getItem(config.akSourceName)
  );
  const dobInput = useRef(null);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  var momentFormat = "MM/DD/YYYY";

  useEffect(() => {
    if (!sourceParam)
      setSourceParam(
        qs.parse(location.search, { ignoreQueryPrefix: true })[
          config.akSourceName
        ]
      ); // If no stored source exists, check QS. Use if found.
  }, [location, sourceParam]);

  function handleSubmit(e) {
    e.preventDefault();
    // Todo - restore all 4 fields to conditional. leaving only first/last for now for demonstration purposes
    let formatted_dob = dob && moment(dob, momentFormat).format("YYYY") + "*";
    let formatted_full_dob =
      dob && moment(dob, momentFormat).format("YYYYMMDD");
    if (email && keepme) {
      var formData = new URLSearchParams();
      formData.append("page", "gavgquiz-homepage");
      formData.append("email", email);
      if (firstName) formData.append("first_name", firstName);
      if (lastName) formData.append("last_name", lastName);
      if (dob) formData.append("action_dob", formatted_full_dob);
      if (zip) formData.append("zip", zip);
      if (sourceParam) formData.append("source", sourceParam);

      window.session.postSubscribeEmail(formData);
    }
    if (firstName && lastName && dob && zip) {
      // if (firstName && lastName) {
      let reqconfig = {
        params: {
          first_name: firstName,
          last_name: lastName,
          zip_code: zip,
          dob: formatted_dob,
          state: "GA",
        },
      };
      let url = config.apis.crmurl;
      axios
        .get(url, reqconfig)
        .then((response) => {
          if (response.data.result === true) {
            if (response.data.result_set[0]["vb.vf_cd"]) {
              setCD(response.data.result_set[0]["vb.vf_cd"]);
            }
            if (response.data.result_set[0]["vb.vf_sd"]) {
              setSD(response.data.result_set[0]["vb.vf_sd"]);
            }
            if (response.data.result_set[0]["vb.vf_hd"]) {
              setHD(response.data.result_set[0]["vb.vf_hd"]);
            }
            setStatus("HIT");
            setStep(4);
          } else {
            setStatus("MISS");
            setStep(5);
          }
        })
        .catch((error) => {
          setStatus("ERROR");
        });
    } else {
      setStep(5);
    }
  }
  function handleChangeDOB(e) {
    setDOB(e);
  }
  function handleChangeZip(e) {
    if (
      e.target.value.match("(^[0-9]+$|^$|^s$)") != null &&
      !(e.target.value.length > 5)
    ) {
      setZip(e.target.value);
    }
  }
  const resetRegCheck = () => {
    setStep(1);
    setZip("");
    setCD(0);
    setSD(0);
    setHD(0);
    setDOB("");
  };

  function checkFormCompletion() {
    if (firstName && lastName && dob && zip) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  }

  const stepZero = (
    <div>
      <div className="title">{t("quiz_check_your_voter")}</div>
      <p>{t("quiz_double_check")}</p>{" "}
      <button type="button" onClick={() => setStep(1)} className="btn-start">
        {t("start")}
      </button>
    </div>
  );
  const stepOne = (
    <div>
      <div className="title">{t("have_you_cast_a_vote")}</div>
      <Container className="quiz">
        <Row>
          <Col>
            <button
              type="button"
              onClick={() => setStep(2)}
              className={
                "btn-start btn-yes-no btn-quiz language-customization-" +
                i18n.language
              }
            >
              <Trans i18nKey="yes"></Trans>
            </button>
          </Col>
          <Col>
            <button
              type="button"
              onClick={() => setStep(3)}
              className={
                "btn-start btn-yes-no btn-quiz language-customization-" +
                i18n.language
              }
            >
              <Trans i18nKey="no"></Trans>
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              type="button"
              onClick={() => setStep(3)}
              className="btn-second btn-quiz"
            >
              {t("i_dont_know")}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
  const stepTwo = (
    <div>
      <div className="title">{t("reconfirm_your_registration")}</div>
      <p>{t("it_important")}</p>
      <Container>
        <Form id="vrcheck" onSubmit={(e) => handleSubmit(e)}>
          <Row noGutters>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("first_name") + "*"}
                className="form-input"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("last_name") + "*"}
                className="form-input"
                onChange={(e) => {
                  setLastName(e.target.value);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">Date of Birth</Form.Label>
              <IMaskInput
                mask={Date}
                max={new Date(2007, 1, 1)}
                lazy={true}
                unmask={true}
                className="form-input form-control"
                inputRef={dobInput}
                onFocus={() => {
                  dobInput.current.placeholder = "MM/DD/YYYY";
                }}
                onBlur={() => {
                  dobInput.current.placeholder = t("dob") + "*";
                }}
                placeholder={t("dob") + "*"}
                onChange={(e) => {
                  handleChangeDOB(e.target.value);
                  checkFormCompletion();
                }}
                value={dob}
                pattern={momentFormat}
                format={function (date) {
                  return moment(date).format(momentFormat);
                }}
                parse={function (str) {
                  return moment(str, momentFormat);
                }}
                blocks={{
                  YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 2007,
                  },
                  MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    autofix: "pad",
                  },
                  DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    autofix: "pad",
                  },
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">ZIP code</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("zip") + "*"}
                className="form-input"
                value={zip}
                onChange={(e) => {
                  handleChangeZip(e);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} md={12} style={{ padding: "0 5px" }}>
              <Form.Control
                type="email"
                placeholder={t("email_optional")}
                className="form-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  checkFormCompletion();
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className="ctn-keep-me clickable"
              style={{ display: `${email ? "block" : "none"}` }}
              onClick={() => setKeepme(!keepme)}
            >
              <Image src={iconCheck} className="ctn-keep-me-icon" />
              {keepme ? (
                <Image src={iconChecked} className="ctn-keep-me-icon-checked" />
              ) : (
                ""
              )}
              <div className="ctn-keep-me-text">{t("keep_me_up_to")}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                type="submit"
                className={
                  formFilled ? "btn-second " : "btn-second btn-second-inactive"
                }
              >
                {t("continue")}
              </button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
  const stepThree = (
    <div>
      <div className="title">{t("lets_check")}</div>
      <p>{t("even_if_you_havent")}</p>
      <Container>
        <Form id="vrcheck" onSubmit={(e) => handleSubmit(e)}>
          <Row noGutters>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("first_name") + "*"}
                className="form-input"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("last_name") + "*"}
                className="form-input"
                onChange={(e) => {
                  setLastName(e.target.value);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">Date of Birth</Form.Label>
              <IMaskInput
                mask={Date}
                max={new Date(2007, 1, 1)}
                lazy={true}
                unmask={true}
                className="form-input form-control"
                inputRef={dobInput}
                onFocus={() => {
                  dobInput.current.placeholder = "MM/DD/YYYY";
                }}
                onBlur={() => {
                  dobInput.current.placeholder = t("dob") + "*";
                }}
                placeholder={t("dob") + "*"}
                onChange={(e) => {
                  handleChangeDOB(e.target.value);
                  checkFormCompletion();
                }}
                value={dob}
                pattern={momentFormat}
                format={function (date) {
                  return moment(date).format(momentFormat);
                }}
                parse={function (str) {
                  return moment(str, momentFormat);
                }}
                blocks={{
                  YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 2007,
                  },
                  MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    autofix: "pad",
                  },
                  DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    autofix: "pad",
                  },
                }}
                required
              />
            </Col>
            <Col xs={12} md={6} style={{ padding: "0 5px" }}>
              <Form.Label className="secondName">ZIP code</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("zip") + "*"}
                className="form-input"
                value={zip}
                onChange={(e) => {
                  handleChangeZip(e);
                  checkFormCompletion();
                }}
                required
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} md={12} style={{ padding: "0 5px" }}>
              <Form.Control
                type="email"
                placeholder={t("email_optional")}
                className="form-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  checkFormCompletion();
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className="ctn-keep-me clickable"
              style={{ display: `${email ? "block" : "none"}` }}
              onClick={() => setKeepme(!keepme)}
            >
              <Image src={iconCheck} className="ctn-keep-me-icon" />
              {keepme ? (
                <Image src={iconChecked} className="ctn-keep-me-icon-checked" />
              ) : (
                ""
              )}
              <div className="ctn-keep-me-text">{t("keep_me_up_to")}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                type="submit"
                className={
                  formFilled ? "btn-second " : "btn-second btn-second-inactive"
                }
              >
                {t("continue")}
              </button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
  const stepFour = (
    <div>
      <Image
        src={checkRegister}
        alt="check-register"
        style={{ marginBottom: "10px" }}
      />

      <div className="title">
        {t("confirmed")}
        <br />
        {t("you_registered")}
      </div>
      {/* <p>{t("your_district_may")}</p>
      <p>
        {CD && t("us_congressional_district") + parseInt(CD, 10)}
        {CD && <br />}
        {SD && t("state_senate_district") + parseInt(SD, 10)}
        {SD && <br />}
        {HD && t("state_house_district") + parseInt(HD, 10)}
      </p>
      <p className="last-step">{t("how_do_you_plan")}</p>
      <Container className="p-0">
        <Row noGutters>
          <Col className="col-12 col-md-6 my-1 px-1">
            <Link to="/vote-by-mail-lpqs">
              <button type="button" className="btn-start btn-small btn-plan">
                {t("by_mail")}
              </button>
            </Link>
          </Col>
          <Col className="col-12 col-md-6 my-1 px-1">
            <Link to="/vote-in-person-early-lpqs">
              <button type="button" className="btn-start btn-small btn-plan">
                {t("before_election_day")}
              </button>
            </Link>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="col-12 col-md-6 my-1 px-1">
            <Link to="/vote-in-person-lpqs">
              <button type="button" className="btn-start btn-small btn-plan">
                {t("on_election_day")}
              </button>
            </Link>
          </Col>
          <Col className="col-12 col-md-6 my-1 px-1">
            <Link to="/learn-more-lpqs">
              <button type="button" className="btn-start btn-small btn-plan">
                {t("im_not_sure")}
              </button>
            </Link>
          </Col>
        </Row>
      </Container> */}
    </div>
  );
  const stepFive = (
    <div>
      <Image src={infoRegister} alt="info-register" />
      <div className="title">{t("we_werent_able_to_find")}</div>
      <p>{t("factors_like_a_move")}</p>
      <Container className="pt-2">
        <Row noGutters>
          <Col>
            <Link to="/register-to-vote-lpq">
              <button type="button" className="btn-start btn-medium">
                {t("learn_more")}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <p className="pt-2">
        <Trans i18nKey="if_you_have_a_criminal_record">
          <HashLink to="/voting-with-a-record"></HashLink>
        </Trans>
      </p>
      <div className="">
        <div className="regcheck-start-over clickable" onClick={resetRegCheck}>
          {t("start_over")}{" "}
          <Image
            src={iconStartOver}
            className="btn-startover"
            alt="Registration Check - Start Over"
          />
        </div>
      </div>
    </div>
  );
  const renderQuestions = (step) => {
    switch (step) {
      case 0:
        return stepZero;
      case 1:
        return stepOne;
      case 2:
        return stepTwo;
      case 3:
        return stepThree;
      case 4:
        return stepFour;
      case 5:
        return stepFive;
      default:
        return stepZero;
    }
  };
  return (
    <div id="voter-status" className="ctn-answer-questions">
      <Container fluid>
        <Row>
          <Col xs="12" lg="6" className={"mobile-hide col-left"}>
            <Image src={altColorV2} alt="" />
          </Col>
          <Col xs="12" lg="6" className="col-right">
            {renderQuestions(quizStep)}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AnswerQuestions;
