import React from "react";
import { Image } from "react-bootstrap";
import "./QuizLandingPage.style.scss";
import { starIconOrange, iconPlus, iconMinus } from "../../lib/images";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
function QuizLPVoteInPerson3() {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }

  return (
    <div className="ctn-quiz-lp04 ctn-quiz-lp06">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>
            Georgians made history during the last election, and now we have to
            protect what we fought for.
          </p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <div className="quiz-lp-btn-action">
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen1("true");
            goToAnchor("when");
            return false;
          }}
        >
          when
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen2("true");
            goToAnchor("where");
            return false;
          }}
        >
          where
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen3("true");
            goToAnchor("why");
            return false;
          }}
        >
          why
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen4("true");
            goToAnchor("how");
            return false;
          }}
        >
          how
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen5("true");
            goToAnchor("whatif");
            return false;
          }}
        >
          what if
        </button>
      </div>
      <div className="collape-item" id="when">
        <div className="collape-item-top">
          <div className="collape-item-lable">when </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-01"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-01" className="collapse-text-content">
            {/* <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">Early voting starts October 17th and goes all the way through November 4th. That means you have over half a month to cast your vote early and skip election day lines.</div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP01} />
              </div>
            </div> */}
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="where">
        <div className="collape-item-top">
          <div className="collape-item-lable">where </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-01"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>
          <div id="collapse-text-02" className="collapse-text-content">
            {/* <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  You’ll need to cast a vote in the county in which you are registered. Since you’re voting early, you can vote at any precinct within your registered county. Follow this link to see all of the available voting precincts in your county.
                </div>
                <button type="button" className="btn-action-quiz-lp">Find my voting precinct</button>
                <div className="div-warning">
                  <Image src={iconWarning} className="div-warning-icon" /><div className="important-text">important:</div>
                  <div className="div-warning-title">Some voting places are open every weekday, but it depends on location — so you should check before you go.</div>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP02} />
              </div>
            </div> */}
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="why">
        <div className="collape-item-top">
          <div className="collape-item-lable">why </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen3(!open3)}
            aria-controls="collapse-text-03"
            aria-expanded={open3}
          >
            {open3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open3}>
          <div id="collapse-text-03" className="collapse-text-content">
            {/* <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  The most appealing reason to cast your ballot early is to skip the election day lines. Plus, you get it out of the way so that you can spend election day celebrating wins, instead of standing in line.
                  Voting early also means less stress for poll workers on election day.
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP03} />
              </div>
            </div> */}
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="how">
        <div className="collape-item-top">
          <div className="collape-item-lable">how </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen4(!open4)}
            aria-controls="collapse-text-04"
            aria-expanded={open4}
          >
            {open4 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open4}>
          <div id="collapse-text-04" className="collapse-text-content">
            {/* <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  If you’re registered, you can vote in person. All you need is a photo ID. Below is a list of acceptable IDs.
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    First, you fill out your ballot with blue or black ink, taking care to fill in ovals completely.
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    ID card issued by the state of Georgia or the federal government
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Free voter ID card issued by the state or county
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">U.S. passport
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Valid employee ID card containing a photograph from any branch, department, agency, or entity of US/GA govt or any county, municipality, board, authority or other entity of GA
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Valid U.S. military identification card
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Valid tribal photo ID
                  </div>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP04} />
              </div>

            </div> */}
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="collapse-05">
        <div className="collape-item-top">
          <div className="collape-item-lable">Tips for early voters </div>

          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen5(!open5)}
            aria-controls="collapse-text-03"
            aria-expanded={open5}
          >
            {open5 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open5}>
          <div id="collapse-text-05" className="collapse-text-content">
            {/* <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Check to make sure your voting precinct is open before you head out
                  </div>
                </div>

                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Bring water, especially if it’s hot
                  </div>
                </div>

                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Tip 3 Goes here and here
                  </div>
                </div>

                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    Tip 4 Goes here and here
                  </div>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP05} />
              </div>

            </div> */}
          </div>
        </Collapse>
      </div>
    </div>
  );
}
export default QuizLPVoteInPerson3;
