//import React, { useState, useEffect } from 'react';
import React from "react";
import {
  Header,
  Footer,
  LineSplit,
  SignMeUp,
  AnswerQuestions,
  MoreInfo,
  AlertSignup,
  VoterGuide,
  Calendar,
} from "../components";
//import MediaQuery from 'react-responsive';
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import "./views.styles.scss";
import { useTranslation } from "react-i18next";

function HomePage() {
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.home.image,
  ].join("");
  let ogUrl = window.location.origin;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("seo_default_home_title")}</title>
        <meta
          name="description"
          content={t("seo_default_home_page_description")}
        />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_home_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_home_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_default_home_title")} />
        <meta
          itemProp="description"
          content={t("seo_default_home_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_default_home_page_title")} />
        <meta
          property="og:description"
          content={t("seo_default_home_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />

      <div id="page-wrap">
        <LineSplit />
        <VoterGuide isHome={true} />
        <LineSplit />
        <AnswerQuestions />
        <LineSplit />
        <Calendar isHome={true} />
        {/* <MoreInfo/> */}
        <LineSplit />
        <SignMeUp />
        <LineSplit />
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default HomePage;
